import HeaderTop from '../../../components/Header/top';
import {
  Button,
  Card,
  Divider,
  Group,
  LoadingOverlay,
  Table,
  Text,
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import React from 'react';
import { ICustomSetting } from '../../../services/types/workspace';
import { SettingsCustomAddRow } from './AddRow';
import { useSettingsCustomsQuery } from '../../../services/settings.service';
import { useGetCountryQuery } from '../../../services/lookup.service';
import _ from 'lodash';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { DeleteRow } from './DeleteRow';

const SettingsCustom: React.FC<{}> = () => {
  useDocumentTitle('ByeLabel - Customs Settings');
  const { data, isLoading } = useSettingsCustomsQuery(null);
  const countries = useGetCountryQuery(null);
  const countriesData = countries?.data || [];

  const rows = (data || [])?.map((element: ICustomSetting) => {
    const country: any = element?.country_code
      ? _.find(countriesData, { code: element.country_code })
      : null;
    return (
      <Table.Tr ta={'center'} key={`custom-setting-${element.id}`}>
        <Table.Td>{`${country?.flag || ''} ${country?.name || '-'}`}</Table.Td>
        <Table.Td>
          {element?.price === 0 && <Text>Whatever the price</Text>}
          {element?.price > 0 && (
            <Group justify={'center'}>
              <Text>If the price {'>='}</Text>
              <Text fw={'bold'}>{element.price.toFixed(2)}</Text>
            </Group>
          )}
        </Table.Td>
        <Table.Td>
          <Text>{element?.rate}%</Text>
        </Table.Td>
        <Table.Td>
          <Group justify={"center"}>
            <SettingsCustomAddRow
              buttonProps={{
                children: <Text>Edit</Text>,
                leftSection: <IconEdit size={14} />,
                color: "orange",
                size: "xs"
              }}
              items={data || ([] as ICustomSetting[])}
              value={element}
            />
            <DeleteRow
              items={data || ([] as ICustomSetting[])}
              id={element?.id}
            />
          </Group>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <HeaderTop title={'Customs Settings'} />
      <Divider my="sm" variant="dashed" />
      <Group justify={'flex-end'}>
        <SettingsCustomAddRow items={data || ([] as ICustomSetting[])} />
      </Group>
      <Card mt={20} p={0} shadow={'sm'} radius={'sm'}>
        <LoadingOverlay
          loaderProps={{ color: 'lime' }}
          visible={isLoading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        <Table
          verticalSpacing="md"
          striped
          highlightOnHover
          withTableBorder
          withColumnBorders
        >
          <Table.Thead
            style={{
              background: 'linear-gradient(to right, #222222, #474747)',
            }}
          >
            <Table.Tr>
              <Table.Th ta={'center'}>Country</Table.Th>
              <Table.Th ta={'center'}>Price</Table.Th>
              <Table.Th ta={'center'}>Tax Percentage</Table.Th>
              <Table.Th ta={'center'}>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Card>
    </>
  );
};

export default SettingsCustom;

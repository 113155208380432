import { Button, Group, Title } from '@mantine/core';
import { IconLogout, IconWindow } from '@tabler/icons-react';
import classes from './Navbar.module.css';
import NavItem from './navItem';
import { IoAppsOutline, IoSettingsOutline } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { useLogoutQuery } from '../../../services/auth.service';
import { useEffect, useState } from 'react';
import { unsetAuth } from '../../../services/system/auth';

export function Navbar() {
  const location = useLocation();
  const [logoutStart, setLogoutStart] = useState(true);
  const logout = useLogoutQuery(null, { skip: logoutStart });

  const logoutHandler = (event: any) => {
    event.preventDefault();
    setLogoutStart(false);
  };

  useEffect(() => {
    if (logout.isSuccess) {
      unsetAuth();
      window.location.href = '/login';
    }
  }, [logout.isSuccess]);

  useEffect(() => {
    if (logout.isError) {
      setLogoutStart(true);
    }
  }, [logout.isError]);

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>
        <Group className={classes.header} justify="center">
          <Title c={'#fff'} fw={400} fz={30} ta={'center'}>
            <b style={{ fontWeight: 600 }}>Bye</b>Label
          </Title>
        </Group>
        <NavItem
          index={0}
          label={'Dashboard'}
          icon={<IoAppsOutline size={20} />}
          link={'/'}
        />
        <NavItem
          index={1}
          label={'Workspace'}
          icon={<IconWindow size={20} />}
          link={'/workspace'}
        />

        <NavItem
          location={location}
          index={3}
          label={'Settings'}
          icon={<IoSettingsOutline color={'#fff'} size={20} />}
          link={'/'}
          links={[
            { label: 'Profit Settings', link: '/settings/profit' },
            { label: 'Customs Settings', link: '/settings/custom' },
          ]}
        />
      </div>

      <div className={classes.footer}>
        <Button
          justify={'flex-start'}
          loading={logout.isLoading}
          fullWidth
          onClick={logoutHandler}
          leftSection={<IconLogout className={classes.linkIcon} stroke={1.5} />}
          variant="subtle"
          color="rgba(255, 255, 255, 1)"
        >
          Logout
        </Button>
      </div>
    </nav>
  );
}

export default Navbar;

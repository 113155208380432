import { AppShell, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useMeQuery } from '../../services/auth.service';
import Navbar from './components/navbar';
import { isAuth } from '../../services/system/auth';

interface Props {
  children: React.ReactNode;
}

export const Content = ({ children }: Props) => {
  const [opened, { toggle }] = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const {
    data: meData,
    isLoading,
    isSuccess,
    isError,
  } = useMeQuery({}, { skip: !isAuth() });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isSuccess) {
      setLoading(false);
    }
  }, [isSuccess]);

  if (
    pathname === '/login' ||
    pathname === '/forgot' ||
    pathname === '/register'
  ) {
    return <>{children}</>;
  }

  if (isAuth()) {
    return (
      <AppShell
        navbar={{
          width: 300,
          breakpoint: 'sm',
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        {loading && (
          <LoadingOverlay
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2, color: 'orange' }}
            visible={true}
          />
        )}
        {!loading && (
          <>
            <AppShell.Navbar p={0} h={'100%'} top={0}>
              <Navbar />
            </AppShell.Navbar>
            <AppShell.Main pt={0}>{children}</AppShell.Main>
          </>
        )}
      </AppShell>
    );
  } else {
    return <Navigate to="/login" state={{ from: window.location.pathname }} />;
  }
};

export default Content;

import { useDocumentTitle } from '@mantine/hooks';
import HeaderTop from '../../components/Header/top';
import { Card, Divider, LoadingOverlay, Table, Text } from '@mantine/core';
import React from 'react';
import { useGetWorkspacesListQuery } from '../../services/workspace.service';
import { IWorkspace } from '../../services/types/workspace';

export const Dashboard = () => {
  useDocumentTitle('ByeLabel - Workspace');

  const { data, isLoading } = useGetWorkspacesListQuery(null);
  const items = data?.data || [];

  const rows = (items || [])?.map((element: IWorkspace) => {
    return (
      <Table.Tr ta={'center'} key={`workspace-${element.id}`}>
        <Table.Td>
          <Text>{element?.id}</Text>
        </Table.Td>
        <Table.Td>
          <Text c={"teal"}>{element?.name}</Text>
        </Table.Td>
        <Table.Td>
          <Text c={"lime"}>{element?.display_name}</Text>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <HeaderTop title={'Workspace'} />
      <Divider my="sm" variant="dashed" />
      <Card mt={20} p={0} shadow={'sm'} radius={'sm'}>
        <LoadingOverlay
          loaderProps={{ color: 'lime' }}
          visible={isLoading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        <Table
          verticalSpacing="md"
          striped
          highlightOnHover
          withTableBorder
          withColumnBorders
        >
          <Table.Thead
            style={{
              background: 'linear-gradient(to right, #222222, #474747)',
            }}
          >
            <Table.Tr>
              <Table.Th w={400} ta={'center'}>ID</Table.Th>
              <Table.Th ta={'center'}>Name</Table.Th>
              <Table.Th ta={'center'}>Display Name</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Card>
    </>
  );
};

export default Dashboard;

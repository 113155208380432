import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../lib/axiosBaseQuery';
import { UpdateProfileProps } from './types/workspace';
import { setProfile, setUserInfo } from '../store/slices/user.slice';
import { Register, Token, User } from './types/auth';
import { setAuth } from './system/auth';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Me', 'Profile'],
  endpoints: (builder) => ({
    login: builder.mutation<{ session: Token; account: User }, any>({
      query: ({ username, password }) => ({
        url: `/account/login`,
        method: 'POST',
        data: {
          username,
          password,
          device: {
            os: 'web',
            token: 'firebase_token',
          },
        },
      }),
      transformResponse: (result: {
        success: boolean;
        payload: { session: Token; account: User };
      }) => result.payload,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // Set Token
          setAuth(data.session.access.token, data.session.refresh.token);

          await dispatch(setUserInfo(data.account));

          await dispatch(authApi.endpoints.me.initiate(null));
        } catch (error) {
          //     console.log('error code',error)
        }
      },
    }),

    register: builder.mutation<Register, any>({
      query: ({ username, workspace }) => ({
        url: `/account/register`,
        method: 'POST',
        data: {
          username,
          workspace,
          device: {
            os: 'web',
          },
        },
      }),
      transformResponse: (result: { success: boolean; payload: Register }) =>
        result.payload,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
          //     console.log('error code',error)
        }
      },
    }),

    changePassword: builder.mutation<any, any>({
      query: ({ current_password, password, password_again }) => ({
        url: `/account/change-password`,
        method: 'POST',
        data: {
          current_password,
          password,
          password_again,
        },
        includeToken: true,
      }),
      transformResponse: (result: { success: boolean; payload: any }) =>
        result.payload,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
          //     console.log('error code',error)
        }
      },
    }),

    me: builder.query<User, any>({
      query: () => ({ url: `/account/me`, method: 'GET', includeToken: true }),
      transformResponse: (result: { success: boolean; payload: User }) =>
        result.payload,
      providesTags: (result) =>
        result
          ? [{ type: 'Me', id: 'ME_PROFILE' }]
          : [{ type: 'Me', id: 'ME_PROFILE' }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          //    Alert.alert('me data',JSON.stringify(data));

          await dispatch(setUserInfo(data));
        } catch (error) {}
      },
    }),
    logout: builder.query<any, any>({
      query: () => ({
        url: `/account/logout`,
        method: 'POST',
        includeToken: true,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log('logout data', data);
        } catch (error) {}
      },
    }),
    getProfile: builder.query<UpdateProfileProps, any>({
      query: () => ({
        url: `/workspace/settings/profile`,
        method: 'GET',
        includeToken: true,
      }),
      transformResponse: (result: {
        success: boolean;
        payload: UpdateProfileProps;
      }) => result.payload,
      providesTags: (result) =>
        result
          ? [{ type: 'Profile', id: 'PROFILE' }]
          : [{ type: 'Profile', id: 'PROFILE' }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await dispatch(setProfile(data));
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useMeQuery,
  useGetProfileQuery,
  useChangePasswordMutation,
  useLogoutQuery,
} = authApi;

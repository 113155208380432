//pages
//router
import { Navigate, useRoutes } from 'react-router-dom';
import Dashboard from '../app/dashboard';
import Login from '../app/auth/login';
import SettingsProfit from '../app/settings/profit';
import { isAuth } from '../services/system/auth';
import SettingsCustom from '../app/settings/custom';
import Workspace from '../app/workspace';

const isAuthenticated = (): boolean => {
  return isAuth();
};

const requireAuth = (element: JSX.Element): JSX.Element => {
  return isAuthenticated() ? (
    element
  ) : (
    <Navigate to="/login" state={{ from: window.location.pathname }} />
  );
};

const Router = () =>
  useRoutes([
    {
      path: '/',
      element: requireAuth(<Dashboard />),
    },
    {
      path: '/workspace',
      element: requireAuth(<Workspace />),
    },
    {
      path: '/settings/profit',
      element: requireAuth(<SettingsProfit />),
    },
    {
      path: '/settings/custom',
      element: requireAuth(<SettingsCustom />),
    },
    {
      path: '/login',
      element: <Login />,
    },
  ]);

export default Router;

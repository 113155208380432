import React, { useEffect, useState } from 'react';
import {
  ActionIcon,
  Alert,
  Avatar,
  Button,
  Combobox,
  ComboboxProps,
  Group,
  InputBase,
  Popover,
  Tooltip,
  useCombobox,
} from '@mantine/core';
import { useGetCarrierQuery } from '../../../services/lookup.service';
import { modifySvgSize } from '../../../lib/hooks';
import { IoCheckmarkDone, IoTrash } from 'react-icons/io5';
import { IconInfoCircle, IconX } from '@tabler/icons-react';
import { useProfitRuleDeleteMutation } from '../../../services/settings.service';
import { notifications } from '@mantine/notifications';
import _ from 'lodash';

interface CarrierComboboxProps extends ComboboxProps {
  onSelected: (value: any) => void;
  input: any;
  initialValue?: any;
}

export const CarrierCombobox: React.FC<CarrierComboboxProps> = ({
  onSelected,
  input,
  initialValue,
  ...props
}) => {
  const carriers = useGetCarrierQuery(null);
  const carriersData: any[] = carriers?.data
    ? [{ code: '__all__', name: 'All' }, ...carriers?.data]
    : [];
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [value, setValue] = useState<string | null>(null);
  const [search, setSearch] = useState<any>(initialValue || '');

  const shouldFilterOptions = carriersData.every(
    (item: any) => item.name !== search?.name,
  );

  const filteredOptions = shouldFilterOptions
    ? carriersData.filter((item) =>
        item?.name?.toLowerCase().includes(search?.toLowerCase()?.trim()),
      )
    : carriersData;

  useEffect(() => {
    if (initialValue && carriersData.length > 0) {
      const findCarrier = _.find(
        carriersData,
        (item) => item.code === initialValue,
      );
      if (findCarrier) {
        setSearch(findCarrier);
        input.value = findCarrier;
        combobox.updateSelectedOptionIndex();
      }
    }
  }, [initialValue, carriersData]);

  useEffect(() => {
    onSelected(search);
  }, [search]);

  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item} key={item.code}>
      <Group>
        {item?.logo?.data ? (
          <div
            style={{
              width: '50px',
              height: '50px',
              background: '#FFFFFF',
              borderRadius: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            dangerouslySetInnerHTML={{
              __html: modifySvgSize(item?.logo?.data, '36px', '36px'),
            }}
          />
        ) : (
          <Avatar color="cyan" radius={5} size={50}>
            ALL
          </Avatar>
        )}
        {item.name}
      </Group>
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        setValue(val);
        setSearch(val);
        combobox.closeDropdown();
      }}
      {...props}
    >
      <Combobox.Target>
        <InputBase
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(value || '');
          }}
          placeholder="Search value"
          label="Carrier"
          value={search?.name || ''}
          onChange={(event) => {
            const selectedName = event.currentTarget.value;
            const selectedCarrier = _.find(carriersData, (item) =>
              item.name.toLowerCase().includes(selectedName.toLowerCase()),
            );
            setSearch(selectedCarrier || { name: selectedName });
            {
              /* Name ile arama yap */
            }
            onSelected(selectedCarrier || selectedName);
            combobox.updateSelectedOptionIndex();
          }}
          {...input}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export const DeleteActionButton: React.FC<{ id: string; filterBy: string; }> = ({ id, filterBy }) => {
  const icon = <IconInfoCircle />;
  const [deletedProfit, deletedProfitProps] = useProfitRuleDeleteMutation();

  useEffect(() => {
    if (deletedProfitProps.isSuccess) {
      notifications.show({
        position: 'top-right',
        withCloseButton: true,
        autoClose: 4000,
        title: 'Profit Delete',
        message: 'Profit was successfully deleted',
        color: 'lime',
        icon: <IoCheckmarkDone />,
        style: { backgroundColor: 'green' },
        styles: {
          description: { color: 'white' },
          title: { fontWeight: 'bold', color: 'white' },
        },
        loading: false,
      });
    }
  }, [deletedProfitProps.isSuccess]);

  useEffect(() => {
    if (deletedProfitProps.error) {
      notifications.show({
        position: 'top-right',
        withCloseButton: true,
        autoClose: 4000,
        title: 'Profit Delete',
        message: (deletedProfitProps.error as any)?.data?.message || 'Error',
        color: 'red',
        icon: <IconX />,
        style: { backgroundColor: '#570d0d' },
        styles: {
          description: { color: 'white' },
          title: { fontWeight: 'bold', color: 'white' },
        },
        loading: false,
      });
    }
  }, [deletedProfitProps.error]);

  const yesDeleteHandler = (e: any) => {
    e.preventDefault();
    deletedProfit({ id,filterBy });
  };

  return (
    <Popover width={300} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Tooltip color={'red'} label="Delete">
          <ActionIcon
            loading={deletedProfitProps.isLoading}
            variant="light"
            color="red"
            radius="xs"
            aria-label="Delete"
          >
            <IoTrash />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown bg="var(--mantine-color-body)">
        <Alert variant="outline" color="blue" title="Delete" icon={icon}>
          Do you really want to delete this data?
        </Alert>
        <Group mt={10} style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={yesDeleteHandler}
            loading={deletedProfitProps.isLoading}
            variant="filled"
            color="red"
            radius="xs"
          >
            Yes, Delete
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

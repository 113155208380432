import React, { useEffect, useState } from 'react';
import {
  Button, ButtonProps,
  Divider,
  Group,
  NumberInput,
  Popover,
  Select, Text,
} from '@mantine/core';
import { IconPercentage, IconPlus } from '@tabler/icons-react';
import { useGetCountryQuery } from '../../../services/lookup.service';
import { IoLogoUsd } from 'react-icons/io5';
import { useForm } from '@mantine/form';
import { ICustomSetting } from '../../../services/types/workspace';
import { useSettingsCustomsSaveMutation } from '../../../services/settings.service';
import _ from 'lodash';

export const SettingsCustomAddRow: React.FC<{
  items: ICustomSetting[];
  value?: ICustomSetting;
  buttonProps?: ButtonProps;
}> = ({ items, value, buttonProps }) => {
  const [opened, setOpened] = useState<boolean>(false);

  const [settingsCustomsSave, settingsCustomsSaveProps] =
    useSettingsCustomsSaveMutation();
  const countries = useGetCountryQuery(null);
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      country_code: null,
      price: 0,
      rate: 0,
    },
    validate: {
      country_code: (value) =>
        value !== '' && value !== null && value !== undefined
          ? null
          : 'Country cannot be left blank!',
      // price: (value) => (value !== '' ? null : 'Weight must be greater than 0'),
      //  rate: (value) => (value !== '' ? null : 'Multiplier must be greater than 0'),
    },
  });

  useEffect(() => {
    if (value) {
      form.setValues(value as ICustomSetting as any);
    }
  }, [value]);

  const onSubmitHandler = (values: ICustomSetting) => {
    console.log('custom setting add values', values);
    console.log('items', items);
    settingsCustomsSave({ customs: [...items.filter((item) => item.country_code !== values.country_code), values] });
  };

  useEffect(() => {
    if (settingsCustomsSaveProps.isSuccess) {
      setOpened(false);
    }
  }, [settingsCustomsSaveProps.isSuccess]);

  const onClose = () => {
    form.reset();
  };

  return (
    <Popover
      width={300}
      position="left-start"
      withArrow
      shadow="md"
      opened={opened}
      onChange={setOpened}
      onClose={onClose}
    >
      <Popover.Target>
        <Button
          onClick={() => setOpened((o) => !o)}
          leftSection={<IconPlus size={14} />}
          variant="filled"
          color="green"
          radius="xs"
          loading={settingsCustomsSaveProps.isLoading}
          {...(buttonProps && buttonProps)}
        >
          {buttonProps && buttonProps.children}
          {!buttonProps && (<Text>Add</Text>)}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <form onSubmit={form.onSubmit((vals) => onSubmitHandler(vals))}>
          <Select
            label="Country"
            placeholder="Select Country"
            autoComplete="off"
            searchable
            disabled={settingsCustomsSaveProps.isLoading}
            comboboxProps={{ withinPortal: false }}
            data={countries?.data?.map((country) => ({
              value: country.code,
              label: `${country.flag} ${country.name}`,
              disabled: _.some(items, { country_code: country.code }),
            }))}
            key={form.key('country_code')}
            {...form.getInputProps('country_code')}
          />
          <NumberInput
            label="Price"
            placeholder="Price"
            mt={10}
            disabled={settingsCustomsSaveProps.isLoading}
            rightSection={<IoLogoUsd />}
            rightSectionPointerEvents="none"
            key={form.key('price')}
            {...form.getInputProps('price')}
          />
          <NumberInput
            label="Tax Percentage Value"
            placeholder="Tax Percentage"
            mt={10}
            disabled={settingsCustomsSaveProps.isLoading}
            rightSection={<IconPercentage />}
            rightSectionPointerEvents="none"
            key={form.key('rate')}
            {...form.getInputProps('rate')}
          />
          <Divider my="sm" variant="dashed" />
          <Group justify="flex-end" mt="md">
            <Button
              loading={settingsCustomsSaveProps.isLoading}
              color={'teal'}
              type="submit"
            >
              Save
            </Button>
          </Group>
        </form>
      </Popover.Dropdown>
    </Popover>
  );
};

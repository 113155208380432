import React, { useEffect } from 'react';
import { IconInfoCircle, IconX } from '@tabler/icons-react';
import { useSettingsCustomsSaveMutation } from '../../../services/settings.service';
import { notifications } from '@mantine/notifications';
import { IoCheckmarkDone, IoTrash } from 'react-icons/io5';
import { Alert, Button, Group, Popover, Text } from '@mantine/core';
import { ICustomSetting } from '../../../services/types/workspace';

export const DeleteRow: React.FC<{ items: ICustomSetting[]; id?: string }> = ({
  items,
  id,
}) => {
  const icon = <IconInfoCircle />;
  const [settingsCustomsSave, settingsCustomsSaveProps] =
    useSettingsCustomsSaveMutation();
  useEffect(() => {
    if (settingsCustomsSaveProps.isSuccess) {
      notifications.show({
        position: 'top-right',
        withCloseButton: true,
        autoClose: 4000,
        title: 'Customs Settings',
        message: 'Custom was successfully deleted',
        color: 'lime',
        icon: <IoCheckmarkDone />,
        style: { backgroundColor: 'green' },
        styles: {
          description: { color: 'white' },
          title: { fontWeight: 'bold', color: 'white' },
        },
        loading: false,
      });
    }
  }, [settingsCustomsSaveProps.isSuccess]);

  useEffect(() => {
    if (settingsCustomsSaveProps.error) {
      notifications.show({
        position: 'top-right',
        withCloseButton: true,
        autoClose: 4000,
        title: 'Customs Settings',
        message:
          (settingsCustomsSaveProps.error as any)?.data?.message || 'Error',
        color: 'red',
        icon: <IconX />,
        style: { backgroundColor: '#570d0d' },
        styles: {
          description: { color: 'white' },
          title: { fontWeight: 'bold', color: 'white' },
        },
        loading: false,
      });
    }
  }, [settingsCustomsSaveProps.error]);

  const yesDeleteHandler = (e: any) => {
    e.preventDefault();
    settingsCustomsSave({ customs: items.filter((item) => item.id !== id) });
  };

  return (
    <Popover width={300} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Button
          color="red"
          size="xs"
          leftSection={<IoTrash />}
          loading={settingsCustomsSaveProps.isLoading}
        >
          <Text>Delete</Text>
        </Button>
      </Popover.Target>
      <Popover.Dropdown bg="var(--mantine-color-body)">
        <Alert variant="outline" color="blue" title="Delete" icon={icon}>
          Do you really want to delete this data?
        </Alert>
        <Group mt={10} style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={yesDeleteHandler}
            loading={settingsCustomsSaveProps.isLoading}
            variant="filled"
            color="red"
            radius="xs"
          >
            Yes, Delete
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

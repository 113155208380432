import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../lib/axiosBaseQuery';
import { WorkspacePayload, WorkspaceTransaction } from './types/workspace';

export const workspaceApi = createApi({
  reducerPath: 'workspaceApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['WorkspaceList'],
  endpoints: (builder) => ({
    getWorkspacesList: builder.query<WorkspacePayload, null>({
      query: () => ({
        url: `/admin/workspace`,
        method: 'GET',
        includeToken: true,
      }),
      transformResponse: (result: {
        success: boolean;
        payload: WorkspacePayload;
        transaction: WorkspaceTransaction;
      }) => result.payload,
      providesTags: ['WorkspaceList'],
    }),
  }),
});

export const { useGetWorkspacesListQuery } = workspaceApi;

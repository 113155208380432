import {
  Anchor,
  Box,
  Button,
  Checkbox,
  Group,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../../services/auth.service';

interface LoginProps {
  username: string;
  password: string;
  isRemember: boolean;
}

export const Login = () => {
  useDocumentTitle('ByeLabel | Login');

  const [login, loginProps] = useLoginMutation();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
      isRemember: false,
    },

    validate: {
      username: (value) => (value !== '' ? null : 'Username required'),
      password: (value) => (value !== '' ? null : 'Password required'),
    },
  });

  useEffect(() => {
    if (loginProps.isError) {
      setLoading(false);
      notifications.show({
        color: 'red',
        title: 'Error',
        //@ts-ignore
        message: loginProps?.error?.data?.message ?? '',
        autoClose: 2000,
      });
    }
  }, [loginProps.isError]);

  useEffect(() => {
    if (loginProps.isSuccess) {
      notifications.show({
        color: 'teal',
        title: 'Success',
        message: 'Login Successful',
        autoClose: 2000,
      });
      const from = location.state?.from || '/';
      navigate(from, { replace: true });
    }
  }, [loginProps.isSuccess]);

  const setLogin = (values: LoginProps) => {
    setLoading(true);
    login({
      username: values.username,
      password: values.password,
    });
  };

  return (
    <Box
      h={'100svh'}
      w={'100%'}
      display={'flex'}
      style={{ justifyContent: 'center', alignItems: 'center' }}
    >
      <div>
        <Title fw={400} order={1} fz={30} ta={'center'}>
          <b style={{ fontWeight: 600 }}>Bye</b>Label
        </Title>
        <Text mb={20} ta={'center'} fz={'sm'} c={'dimmed'}>
          Login to your ByeLabel account
        </Text>

        <Paper w={420} maw={'100%'} withBorder shadow="md" p={30} radius="md">
          <form onSubmit={form.onSubmit((values) => setLogin(values))}>
            <TextInput
              label="Username"
              placeholder="Your Username"
              {...form.getInputProps('username')}
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              mt="md"
              {...form.getInputProps('password')}
            />
            <Group justify="space-between" mt="lg">
              <Checkbox label="Remember me" />
              <Anchor component={Link} to={'/forgot'} c={'indigo'} size="sm">
                Forgot password?
              </Anchor>
            </Group>
            <Button
              type={'submit'}
              variant={'gradient'}
              gradient={{ from: 'indigo', to: 'blue', deg: 90 }}
              fullWidth
              mt="xl"
              loading={loading}
            >
              Login
            </Button>
          </form>
        </Paper>
      </div>
    </Box>
  );
};

export default Login;

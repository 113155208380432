import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../lib/axiosBaseQuery';
import {
  ProfitRule,
  ProfitRuleAll,
  ProfitRuleSettings,
} from './types/settings';
import { ICustomSetting } from './types/workspace';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ProfitRule', 'CustomSetting'],
  endpoints: (builder) => ({
    profitRuleSave: builder.mutation<
      ProfitRule,
      { items: Omit<ProfitRule, 'id'>; filterBy: string }
    >({
      query: (data) => ({
        url: `/admin/profit-rule/${data.filterBy}`,
        method: 'POST',
        includeToken: true,
        data: { ...data.items },
      }),
      transformResponse: (result: ProfitRuleSettings) => result.payload,
      invalidatesTags: (result, error, { filterBy }) => [
        { type: 'ProfitRule', id: filterBy }
      ],
    }),
    profitRuleEdit: builder.mutation<
      ProfitRule,
      { items: ProfitRule; filterBy: string }
    >({
      query: (data) => ({
        url: `/admin/profit-rule/${data.filterBy}`,
        method: 'POST',
        includeToken: true,
        data: { ...data.items },
      }),
      transformResponse: (result: ProfitRuleSettings) => result.payload,
      invalidatesTags: (result, error, { filterBy }) => [
        { type: 'ProfitRule', id: filterBy }
      ],
    }),
    profitRuleDelete: builder.mutation<any, { id: string, filterBy: string; }>({
      query: (data) => ({
        url: `/admin/profit-rule/${data.id}`,
        method: 'DELETE',
        includeToken: true,
      }),
      invalidatesTags: (result, error, { filterBy }) => [
        { type: 'ProfitRule', id: filterBy }
      ],
    }),
    profitRuleAll: builder.query<ProfitRule[], { filterBy: string }>({
      query: ({ filterBy }) => ({
        url: `/admin/profit-rule/${filterBy}`,
        method: 'GET',
        includeToken: true,
      }),
      transformResponse: (result: ProfitRuleAll) => result.payload,
      providesTags: (result, error, { filterBy }) => [
        { type: 'ProfitRule', id: filterBy }
      ],
    }),
    settingsCustoms: builder.query<ICustomSetting[], null>({
      query: () => ({
        url: `/admin/settings/customs`,
        method: 'GET',
        includeToken: true,
      }),
      transformResponse: (result: {
        success: boolean;
        payload: ICustomSetting[];
      }) => result.payload,
      providesTags: ['CustomSetting'],
    }),
    settingsCustomsSave: builder.mutation<
      ICustomSetting[],
      { customs: ICustomSetting[] }
    >({
      query: ({ customs }) => ({
        url: `/admin/settings`,
        method: 'POST',
        includeToken: true,
        data: { customs },
      }),
      transformResponse: (result: {
        success: boolean;
        payload: ICustomSetting[];
      }) => result.payload,
      invalidatesTags: ['CustomSetting'],
    }),
  }),
});

export const {
  useProfitRuleSaveMutation,
  useProfitRuleAllQuery,
  useProfitRuleDeleteMutation,
  useProfitRuleEditMutation,
  useSettingsCustomsQuery,
  useSettingsCustomsSaveMutation,
} = settingsApi;

import { Box, Button, Group, Text } from '@mantine/core';
import { IoAdd } from 'react-icons/io5';
import React from 'react';

interface Props {
  title: string;
  button?: {
    title: string;
    onClick: () => void;
    leftSection?: React.ReactNode;
  };
}

export const HeaderTop = ({ title, button }: Props) => {
  return (
    <Group pt={20} px={10}>
      <Box flex={1}>
        <Text mt={7} fz={23} fw={600}>
          {title}
        </Text>
      </Box>
      <Group>
        {button && (
          <Button
            h={38}
            radius={7}
            onClick={() => button.onClick()}
            size={'sm'}
            leftSection={button?.leftSection || <IoAdd size={18} />}
          >
            {button.title}
          </Button>
        )}
      </Group>
    </Group>
  );
};
export default HeaderTop;

import { createTheme, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
import React from 'react';
import { Provider } from 'react-redux';
import Content from './components/Content';
import Router from './routes/index';

import { store } from './store';

const theme = createTheme({
  primaryColor: 'indigo',
  fontSmoothing: true,
});

function App() {
  return (
    <Provider store={store}>
      <MantineProvider forceColorScheme={'dark'} theme={theme}>
        <Notifications />
        <ModalsProvider>
          <Content>
            <Router />
          </Content>
        </ModalsProvider>
      </MantineProvider>
    </Provider>
  );
}

export default App;

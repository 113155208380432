export const setAuth = (token: string, refreshToken: string): void => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
};

export const unsetAuth = (redirect: boolean = false): void => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  if(redirect) {
    setTimeout(() => {
      window.location.href = '/login';
    },200);
  }
};

export const isAuth = (): boolean => {
  return localStorage.getItem('token') !== null;
};

export const getAuthInToken = (): string | null => {
  return localStorage.getItem('token');
};

export const getAuthInRefreshToken = (): string | null => {
  return localStorage.getItem('refreshToken');
};


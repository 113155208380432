import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CategoryProps, CountryProps } from '../../services/types/lookup';

export interface LookupState {
  country: Array<CountryProps>;
  category: Array<CategoryProps>;
}

const initialState: LookupState = {
  country: [],
  category: [],
};

export const lookupSlice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    setCountry: (state, action: PayloadAction<Array<CountryProps>>) => {
      state.country = action.payload;
    },
    setCategory: (state, action: PayloadAction<Array<CategoryProps>>) => {
      state.category = action.payload;
    },
    resetAuth: (state) => {
      return initialState;
    },
  },
});

export const { setCountry, setCategory } = lookupSlice.actions;

export default lookupSlice.reducer;

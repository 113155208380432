import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { UpdateProfileProps } from '../../services/types/workspace';
import { User } from '../../services/types/auth';

export interface UserState {
  info: User | null;
  profile: UpdateProfileProps | null;
}

const initialState: UserState = {
  info: null,
  profile: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<User | null>) => {
      state.info = action.payload;
    },
    setProfile: (state, action: PayloadAction<UpdateProfileProps>) => {
      state.profile = action.payload;
    },
    resetAuth: (state) => {
      return initialState;
    },
  },
});

export const { setUserInfo, setProfile, resetAuth } = userSlice.actions;

export default userSlice.reducer;

import HeaderTop from '../../../components/Header/top';
import {
  ActionIcon,
  Badge,
  Box,
  Card,
  Center,
  Divider,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  Table,
  Text,
  Tooltip,
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { IoPencilSharp } from 'react-icons/io5';
import AddProfit, { ProfitMode } from './AddProfit';
import React, { useEffect, useState } from 'react';
import { ProfitRule } from '../../../services/types/settings';
import _ from 'lodash';
import { modifySvgSize } from '../../../lib/hooks';
import { DeleteActionButton } from './components';

import {
  useGetCarrierQuery,
  useGetCountryQuery,
} from '../../../services/lookup.service';
import { useGetWorkspacesListQuery } from '../../../services/workspace.service';
import { useProfitRuleAllQuery } from '../../../services/settings.service';
import { IWorkspace } from '../../../services/types/workspace';
import { FaLessThanEqual } from 'react-icons/fa';

export const SettingsProfit: React.FC<{}> = () => {
  useDocumentTitle('ByeLabel - Profit Settings');
  const [filterBy, setFilterBy] = useState<string>('api');
  const workspaces = useGetWorkspacesListQuery(null);
  const countries = useGetCountryQuery(null);
  const carriers = useGetCarrierQuery(null);
  const allData = useProfitRuleAllQuery({ filterBy });
  const data = allData?.data || [];
  const countriesData = countries?.data || [];
  const carriersData = carriers?.data || [];
  const workspacesData = workspaces?.data?.data || [];
  const [showProfitModal, setShowProfitModal] = useState<boolean>(false);
  const [showEditProfitModal, setShowEditProfitModal] =
    useState<ProfitRule | null>(null);

  const reversedData = data?.slice()?.reverse() || [];

  const onEditProfit = (e: any, element: ProfitRule) => {
    e.preventDefault();
    setShowEditProfitModal(element);
  };

  useEffect(() => {
    if(filterBy) {
      setTimeout(() => {
        allData.refetch();
      },100);
    }
  }, [filterBy]);

  const rows = reversedData.map((element: ProfitRule) => {
    const workspace: IWorkspace[] = element?.workspace_id
      ? (element.workspace_id.map((workspaceId) =>
          _.find(workspacesData, { id: workspaceId }),
        ) as IWorkspace[])
      : ([] as IWorkspace[]);
    const from_country: any = element?.from_country
      ? _.find(countriesData, { code: element.from_country })
      : null;
    const to_country: any = element?.to_country
      ? _.find(countriesData, { code: element.to_country })
      : null;
    const carrier: any =
      element?.carrier !== '__all__'
        ? _.find(carriersData, { code: element.carrier })
        : element?.carrier;
    const service: any =
      element?.service !== '__all__'
        ? _.find(carrier?.services, { code: element?.service })
        : element?.service;
    return (
      <Table.Tr ta={'center'} key={`profit-rule-${element.id}`}>
        <Table.Td>
          <Group grow justify={'center'} gap={10}>
            {workspace &&
              workspace?.length > 0 &&
              workspace.map((workspaceItem) => (
                <Badge
                  key={`workspace-badge-${workspaceItem?.id}`}
                  variant="dot"
                  color="red"
                  h={50}
                  size="lg"
                  radius="xs"
                >
                  {workspaceItem?.display_name || '-'}
                </Badge>
              ))}
            {workspace && workspace?.length === 0 && <Text>ALL</Text>}
          </Group>
        </Table.Td>
        <Table.Td>
          {element?.from_country === 'XX' && <Text>ALL</Text>}
          {element?.from_country !== 'XX' &&
            `${from_country?.flag || ''} ${from_country?.name || '-'}`}
        </Table.Td>
        <Table.Td>
          {element?.to_country === 'XX' && <Text>ALL</Text>}
          {element?.to_country !== 'XX' &&
            `${to_country?.flag || ''} ${to_country?.name || '-'}`}
        </Table.Td>
        <Table.Td>
          {element?.carrier === '__all__' && (
            <Badge variant="light" color="green" radius="xs">
              All
            </Badge>
          )}
          {element?.carrier !== '__all__' && carrier && (
            <Center inline={true}>
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  background: 'rgba(255,255,255,0.9)',
                  padding: 4,
                  borderRadius: 5,
                }}
                dangerouslySetInnerHTML={{
                  __html: modifySvgSize(carrier?.logo?.data, '36px', '36px'),
                }}
              />
            </Center>
          )}
        </Table.Td>
        <Table.Td>
          {element?.service === '__all__' && (
            <Badge variant="filled" color="cyan" radius="xs">
              All
            </Badge>
          )}
          {element?.service !== '__all__' && (
            <Badge variant="outline" color="white" radius="xs">
              {service?.name}
            </Badge>
          )}
        </Table.Td>
        <Table.Td>
          <Group style={{ justifyContent: 'center' }} gap={12}>
            {element.weight <= 0 && <Text>ALL</Text>}
            {element.weight > 0 && (
              <NumberInput
                hideControls
                w={150}
                leftSectionWidth={38}
                decimalScale={2}
                readOnly={true}
                fixedDecimalScale
                value={element.weight}
                styles={{
                  input: { paddingLeft: 48, fontSize: 13, textAlign: 'center' },
                }}
                rightSectionWidth={38}
                rightSection={
                  <Box
                    w={'100%'}
                    h={'100%'}
                    bg={'#3f3f3f'}
                    display={'flex'}
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Text c={'white'} ml={-2} fz={12} fw={500}>
                      LB
                    </Text>
                  </Box>
                }
                leftSection={
                  <Box
                    w={'100%'}
                    h={'100%'}
                    bg={'#3f3f3f'}
                    display={'flex'}
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                  >
                    <FaLessThanEqual color={'white'} size={16} />
                  </Box>
                }
              />
            )}
          </Group>
        </Table.Td>

        <Table.Td>
          <Group style={{ justifyContent: 'center' }} gap={12}>
            <NumberInput
              hideControls
              w={100}
              leftSectionWidth={38}
              defaultValue={5}
              decimalScale={2}
              fixedDecimalScale
              readOnly={true}
              value={(element.multiplier - 1) * 100}
              styles={{ input: { paddingLeft: 48, fontSize: 13 } }}
              leftSection={
                <Box
                  w={'100%'}
                  h={'100%'}
                  bg={'#3f3f3f'}
                  display={'flex'}
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <Text c={'white'} ml={-2} fz={12} fw={500}>
                    %
                  </Text>
                </Box>
              }
            />
            <Text fz={12} fw={500}>
              +
            </Text>
            <NumberInput
              hideControls
              w={100}
              leftSectionWidth={38}
              defaultValue={3}
              decimalScale={2}
              fixedDecimalScale
              readOnly={true}
              value={element?.additional}
              styles={{ input: { paddingLeft: 48, fontSize: 13 } }}
              leftSection={
                <Box
                  w={'100%'}
                  h={'100%'}
                  bg={'#3f3f3f'}
                  display={'flex'}
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <Text c={'white'} ml={-2} fz={12} fw={500}>
                    $
                  </Text>
                </Box>
              }
            />
          </Group>
        </Table.Td>
        <Table.Td>
          <Group style={{ justifyContent: 'center' }} gap={6}>
            <Tooltip color={'yellow'} label="Edit">
              <ActionIcon
                onClick={(e) => onEditProfit(e, element)}
                variant="light"
                color="yellow"
                radius="xs"
                aria-label="Edit"
              >
                <IoPencilSharp />
              </ActionIcon>
            </Tooltip>
            <DeleteActionButton filterBy={filterBy} id={element.id!} />
          </Group>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <HeaderTop
        title={'Profit Settings'}
        button={{
          title: 'Add Profit Rule',
          onClick: () => {
            setShowProfitModal(true);
          },
        }}
      />
      <Divider my="sm" variant="dashed" />
      {showProfitModal && (
        <AddProfit
          filterBy={filterBy}
          mode={ProfitMode.Add}
          onClosed={() => setShowProfitModal(false)}
        />
      )}
      {showEditProfitModal !== null && (
        <AddProfit
          filterBy={filterBy}
          mode={ProfitMode.Edit}
          values={showEditProfitModal}
          onClosed={() => setShowEditProfitModal(null)}
        />
      )}
      <Box>
        <Group>
          <Select
            label="Filter by"
            placeholder="Select item"
            onChange={(value) => setFilterBy(value!)}
            data={[
              { label: 'API', value: 'api' },
              {
                group: 'Subscriptions',
                items: [
                  { label: 'Starter', value: 'starter' },
                  { label: 'Silver', value: 'silver' },
                  { label: 'Gold', value: 'gold' },
                  { label: 'Platinum', value: 'platinum' },
                  { label: 'Custom', value: 'custom' },
                ],
              },
            ]}
          />
        </Group>
      </Box>
      <Card mt={20} p={0} shadow={'sm'} radius={'sm'}>
        <LoadingOverlay
          loaderProps={{ color: 'lime' }}
          visible={allData.isLoading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        <Table
          verticalSpacing="md"
          striped
          highlightOnHover
          withTableBorder
          withColumnBorders
        >
          <Table.Thead
            style={{ background: 'linear-gradient(to right, black, #333)' }}
          >
            <Table.Tr>
              <Table.Th ta={'center'}>Customer(s)</Table.Th>
              <Table.Th ta={'center'}>From Country</Table.Th>
              <Table.Th ta={'center'}>To Country</Table.Th>
              <Table.Th ta={'center'}>Carrier</Table.Th>
              <Table.Th ta={'center'}>Service</Table.Th>
              <Table.Th ta={'center'}>Weight</Table.Th>
              <Table.Th ta={'center'}>Multiplier + Additional</Table.Th>
              <Table.Th ta={'center'}>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {reversedData?.length > 0 && (<Table.Tbody>{rows}</Table.Tbody>)}
          {reversedData?.length === 0 && (<Table.Caption c={"lime"} p={50}>Nothing found</Table.Caption>)}
        </Table>
      </Card>
    </>
  );
};

export default SettingsProfit;

import React, { useEffect, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';
import {
  Box,
  Button,
  Divider,
  Group,
  Loader,
  LoadingOverlay,
  Modal,
  MultiSelect,
  NumberInput,
  Select,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  useGetCarrierQuery,
  useGetCountryQuery,
} from '../../../services/lookup.service';
import { IconPercentage, IconX } from '@tabler/icons-react';
import _ from 'lodash';
import {
  useProfitRuleEditMutation,
  useProfitRuleSaveMutation,
} from '../../../services/settings.service';
import { ProfitRule } from '../../../services/types/settings';
import { notifications } from '@mantine/notifications';
import { IoCheckmarkDone } from 'react-icons/io5';
import { useGetWorkspacesListQuery } from '../../../services/workspace.service';
import { Carrier } from '../../../services/types/lookup';

export enum ProfitMode {
  Add = 'Add',
  Edit = 'Edit',
}

interface AddProfitProps {
  onClosed: () => void;
  values?: ProfitRule;
  mode?: ProfitMode;
  filterBy: string;
}

const AddProfit: React.FC<AddProfitProps> = ({
  onClosed,
  values,
  mode = ProfitMode.Add,
  filterBy,
}) => {
  const [opened, { close }] = useDisclosure(true);
  const workspaces = useGetWorkspacesListQuery(null);
  const countries = useGetCountryQuery(null);
  const carriers = useGetCarrierQuery(null);
  const [profitRuleSave, profitRuleSaveProps] = useProfitRuleSaveMutation();
  const [profitRuleEdit, profitRuleEditProps] = useProfitRuleEditMutation();
  const [loading, setLoading] = useState<boolean>(true);
  const [serviceLoading, setServiceLoading] = useState<boolean>(false);
  const [isEditableService, setIsEditableService] = useState(false);
  const carriersData: Carrier[] = (carriers?.data as unknown as Carrier[])
    ? [
        {
          code: '__all__',
          name: 'All',
          id: null,
          logo: null,
          services: null,
          packaging: [],
          confirmation: [],
        },
        ...(carriers?.data as unknown as Carrier[]),
      ]
    : [];

  const onClose = () => {
    close();
    onClosed();
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const form = useForm({
    initialValues: {
      workspace_id: [],
      id: undefined,
      from_country: 'US',
      to_country: 'XX',
      carrier: '__all__',
      service: '__all__',
      weight: 0,
      multiplier: 0,
      additional: 0,
    },
    validate: {
      workspace_id: (value) =>
        Array.isArray(value) && value !== null && value !== undefined
          ? null
          : 'Workspaces Id cannot be left blank!',
      from_country: (value) =>
        value !== '' && value !== null && value !== undefined
          ? null
          : 'From Country cannot be left blank!',
      to_country: (value) =>
        value !== '' && value !== null && value !== undefined
          ? null
          : 'To Country cannot be left blank!',
      carrier: (value) =>
        value !== '' && value !== null && value !== undefined
          ? null
          : 'Carrier be left blank!',
      service: (value) => (value !== '' ? null : 'Service be left blank!'),
      //  weight: (value) => (value > 0 ? null : 'Weight must be greater than 0'),
      //  multiplier: (value) =>
      //         value > 0 ? null : 'Multiplier must be greater than 0',
      // additional: (value) =>
      //         value > 0 ? null : 'Additional must be greater than 0',
    },
  });

  useEffect(() => {
    if (values) {
      // const _values: any = { ...values };
      // _values.multiplier = ((values?.multiplier - 1) * 100).toFixed(2);
      form.setValues(values as ProfitRule as any);
      setTimeout(() => {
        setIsEditableService(true);
      },50);
    }
  }, [values]);

  const onSubmit = (vals: ProfitRule) => {
    if (mode === ProfitMode.Add) {
      profitRuleSave({ items: vals, filterBy });
    }

    if (mode === ProfitMode.Edit) {
      profitRuleEdit({ items: vals, filterBy });
    }
  };

  useEffect(() => {
    if (profitRuleSaveProps.isSuccess || profitRuleEditProps.isSuccess) {
      onClose();
      notifications.show({
        position: 'top-right',
        withCloseButton: true,
        autoClose: 4000,
        title: 'Profit Settings',
        message: profitRuleSaveProps.isSuccess
          ? 'Profit successfully added'
          : 'Profit successfully edited',
        color: 'lime',
        icon: <IoCheckmarkDone />,
        style: { backgroundColor: 'green' },
        styles: {
          description: { color: 'white' },
          title: { fontWeight: 'bold', color: 'white' },
        },
        loading: false,
      });
    }
  }, [profitRuleSaveProps.isSuccess, profitRuleEditProps.isSuccess]);

  //     setSelectedCarrier(value);
  //                 form.setFieldValue('service', '');
  useEffect(() => {
    if (form.values.carrier && isEditableService) {
      form.setFieldValue('service', '');
      setServiceLoading(true);
      setTimeout(() => {
        setServiceLoading(false);
      }, 1);
    }
  }, [form.values.carrier]);

  useEffect(() => {
    if (profitRuleSaveProps.error || profitRuleEditProps.error) {
      notifications.show({
        position: 'top-right',
        withCloseButton: true,
        autoClose: 4000,
        title: 'Profit Settings',
        message:
          (
            (profitRuleSaveProps.error as any) ||
            (profitRuleEditProps.error as any)
          )?.data?.message || 'Error',
        color: 'red',
        icon: <IconX />,
        style: { backgroundColor: '#570d0d' },
        styles: {
          description: { color: 'white' },
          title: { fontWeight: 'bold', color: 'white' },
        },
        loading: false,
      });
    }
  }, [profitRuleSaveProps.error, profitRuleEditProps.error]);

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        title={
          mode === ProfitMode.Add
            ? 'Add Profit Row'
            : `Edit Profit Row (#${values?.id})`
        }
        centered
        size="lg"
      >
        <LoadingOverlay
          loaderProps={{ color: 'lime' }}
          visible={
            profitRuleSaveProps.isLoading ||
            profitRuleEditProps.isLoading ||
            loading
          }
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        {!loading && (
          <form onSubmit={form.onSubmit((vals) => onSubmit(vals))}>
            <Group grow gap={20}>
              <Select
                label="From Country"
                placeholder="Select Country"
                data={[
                  { value: 'XX', label: 'ALL' },
                  ...(countries?.data || [])?.map((country) => ({
                    value: country.code,
                    label: `${country.flag} ${country.name}`,
                  })),
                ]}
                key={form.key('from_country')}
                {...form.getInputProps('from_country')}
              />
              <Select
                label="To Country"
                placeholder="Select Country"
                searchable
                data={[
                  { value: 'XX', label: 'ALL' },
                  ...(countries?.data || [])?.map((country) => ({
                    value: country.code,
                    label: `${country.flag} ${country.name}`,
                  })),
                ]}
                key={form.key('to_country')}
                {...form.getInputProps('to_country')}
              />
            </Group>
            <Box mt={10}>
              <MultiSelect
                label="Customer(s)"
                placeholder="All Customers"
                data={[
                  ...(workspaces !== null ? workspaces?.data?.data || []: []).map((workspace) => ({
                    value: workspace.id,
                    label: workspace.display_name,
                  })),
                ]}
                key={form.key('workspace_id')}
                {...form.getInputProps('workspace_id')}
              />
            </Box>
            <Select
              label="Carrier"
              placeholder="Carrier"
              mt={10}
              data={carriersData?.map((carrier) => ({
                label: carrier.name!,
                value: carrier.code!,
              }))}
              key={form.key('carrier')}
              {...form.getInputProps('carrier')}
            />
            {!serviceLoading && (
              <Select
                label="Service"
                placeholder="Service"
                mt={10}
                data={[
                  { value: '__all__', label: 'All' },
                  ...(
                    (
                      _.find(carriers?.data, {
                        code: form.values.carrier,
                      }) as any
                    )?.services || []
                  ).map((service: any) => ({
                    value: service.code,
                    label: service.name,
                  })),
                ]}
                key={form.key('service')}
                {...form.getInputProps('service')}
              />
            )}
            {serviceLoading && <Loader mt={10} />}
            <NumberInput
              label="Weight"
              placeholder="Weight"
              mt={10}
              key={form.key('weight')}
              {...form.getInputProps('weight')}
            />
            <Group grow gap={30}>
              <NumberInput
                label="Multiplier"
                placeholder="Multiplier"
                mt={10}
                rightSection={<IconPercentage />}
                rightSectionPointerEvents="none"
                key={form.key('multiplier')}
                {...form.getInputProps('multiplier')}
              />
              <NumberInput
                label="Additional"
                placeholder="Additional"
                mt={10}
                key={form.key('additional')}
                {...form.getInputProps('additional')}
              />
            </Group>
            <Divider my="sm" variant="dashed" />
            <Group justify="flex-end" mt="md">
              <Button color={'lime'} type="submit">
                Save
              </Button>
            </Group>
          </form>
        )}
      </Modal>
    </>
  );
};

export default AddProfit;

import { useDocumentTitle } from '@mantine/hooks';
import HeaderTop from '../../components/Header/top';
import { Divider } from '@mantine/core';
import React from 'react';

export const Dashboard = () => {
  useDocumentTitle('ByeLabel - Dashboard');

  return (
    <>
      <HeaderTop title={'Dashboard'} />
      <Divider my="sm" variant="dashed" />
    </>
  );
};

export default Dashboard;

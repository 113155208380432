import { Accordion, Avatar, Group, NavLink } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { IoChevronDown } from 'react-icons/io5';
import { NavLink as NavRouterLink } from 'react-router-dom';

interface Props {
  label: string;
  icon: React.ReactNode;
  link: string;
  index: number;
  name?: string;
  links?: Array<{
    label: string;
    link: string;
  }>;
  isSub?: boolean;
  location?: any;
}

export const NavItem = ({
  index,
  label,
  icon,
  links = [],
  location,
  link,
  isSub = false,
  name,
}: Props) => {
  const [openedMenu, setOpenedMenu] = useState<string | null>(null);

  useEffect(() => {
    const currentPath = (location?.pathname || '') + (location?.search || '');
    const isActiveSubLink = links.some((item) => item.link === currentPath);
    if (isActiveSubLink) {
      setOpenedMenu(`menu-${index}`);
    }
  }, [location, links, index]);

  return (
    <div>
      {links?.length > 0 && (
        <Accordion
          value={openedMenu}
          onChange={setOpenedMenu}
          chevron={<IoChevronDown size={15} color={'#fff'} />}
        >
          <Accordion.Item
            key={`menu-${index}`}
            value={`menu-${index}`}
            styles={{ item: { border: 'none' } }}
          >
            <Accordion.Control className={'menu-link'} icon={icon}>
              {label}
            </Accordion.Control>
            <Accordion.Panel
              styles={{ content: { paddingBottom: 3, paddingTop: 0 } }}
            >
              {links?.map((item, i) => {
                return (
                  <NavLink
                    key={`menu-${index}-item-${i}`}
                    className={`menu-link`}
                    component={NavRouterLink}
                    variant="filled"
                    to={item.link}
                    label={item.label}
                    active={
                      (location?.pathname || '') + (location?.search || '') ===
                      item.link
                    }
                    c={'#fff'}
                  />
                );
              })}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      )}

      {links.length === 0 && (
        <NavLink
          component={NavRouterLink}
          to={link}
          label={isSub ? <Group>{label}</Group> : label}
          leftSection={
            icon ? (
              icon
            ) : name !== '' ? (
              <Avatar
                styles={{ placeholder: { fontSize: 10 } }}
                color={'indigo'}
                variant={'filled'}
                defaultValue={''}
                radius={0}
                size={20}
              >
                {name && name[0]}
              </Avatar>
            ) : (
              ''
            )
          }
          variant="subtle"
          c={'#fff'}
          className={'menu-link'}
          px={isSub ? 33 : 15}
          py={isSub ? 3 : 8}
        />
      )}
    </div>
  );
};

export default NavItem;

import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../lib/axiosBaseQuery';
import {
  CarrierProps,
  CategoryProps,
  CountryProps,
  SaleChannelProps,
  StateProps,
} from '../services/types/lookup';
import { setCategory, setCountry } from '../store/slices/lookup.slice';

export const lookupApi = createApi({
  reducerPath: 'lookupApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Country'],
  endpoints: (builder) => ({
    getCountry: builder.query<Array<CountryProps>, any>({
      query: () => ({
        url: `/lookup/country`,
        method: 'GET',
        includeToken: true,
      }),
      transformResponse: (result: {
        success: boolean;
        payload: Array<CountryProps>;
      }) => result.payload,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          await dispatch(setCountry(data));
        } catch (error) {}
      },
    }),

    getState: builder.query<Array<StateProps>, { id: string }>({
      query: ({ id }) => ({
        url: `/lookup/state?parent_id=${id}`,
        method: 'GET',
        includeToken: true,
      }),
      transformResponse: (result: {
        success: boolean;
        payload: Array<StateProps>;
      }) => result.payload,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {}
      },
    }),

    getCategory: builder.query<Array<CategoryProps>, any>({
      query: () => ({
        url: `/lookup/category`,
        method: 'GET',
        includeToken: true,
      }),
      transformResponse: (result: {
        success: boolean;
        payload: Array<CategoryProps>;
      }) => result.payload,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          await dispatch(setCategory(data));
        } catch (error) {}
      },
    }),

    getSaleChannel: builder.query<Array<SaleChannelProps>, any>({
      query: () => ({
        url: `/lookup/sales_channel`,
        method: 'GET',
        includeToken: true,
      }),
      transformResponse: (result: {
        success: boolean;
        payload: Array<SaleChannelProps>;
      }) => result.payload,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {}
      },
    }),

    getCarrier: builder.query<Array<CarrierProps>, any>({
      query: () => ({
        url: `/lookup/carrier`,
        method: 'GET',
        includeToken: true,
      }),
      transformResponse: (result: {
        success: boolean;
        payload: Array<CarrierProps>;
      }) => result.payload,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetCountryQuery,
  useGetCategoryQuery,
  useGetStateQuery,
  useGetSaleChannelQuery,
  useGetCarrierQuery,
} = lookupApi;

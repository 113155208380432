export const modifySvgSize = (svg: any, width: any, height: any) => {
  const parser = new DOMParser();

  let decodedSvg;
  try {
    decodedSvg = atob(svg);
  } catch (error) {
    decodedSvg = svg;
  }

  const svgDoc = parser.parseFromString(decodedSvg, 'image/svg+xml');
  const svgElement = svgDoc.documentElement;

  svgElement.setAttribute('width', width);
  svgElement.setAttribute('height', height);

  const serializer = new XMLSerializer();
  return serializer.serializeToString(svgElement);
};

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { authApi } from '../services/auth.service';
import { lookupApi } from '../services/lookup.service';
import { settingsApi } from '../services/settings.service';
import { workspaceApi } from '../services/workspace.service';

// Reducers
import userReducer from './slices/user.slice';
import lookupReducer from './slices/lookup.slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    lookup: lookupReducer,
    [authApi.reducerPath]: authApi.reducer,
    [lookupApi.reducerPath]: lookupApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [workspaceApi.reducerPath]: workspaceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      authApi.middleware,
      lookupApi.middleware,
      settingsApi.middleware,
      workspaceApi.middleware,
    ]),
});

// getDefaultMiddleware().concat([authApi.middleware, homeApi.middleware, wallApi.middleware, phoneBookApi.middleware, advertApi.middleware,chatApi.middleware, calendarApi.middleware])

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
